<template>
	<div class="search_bg">
		<div class="px-0 px-sm-4 wrapper">
			<v-col
				cols="12"
				class="pa-0 px-4"
				align="center"
			>
				<div class="content_title">
					마루추천
					<p class="hidden-sm-and-down sub_title">사진만 올리면 어울리는 마루를 추천해드려요!</p>
				</div>
			</v-col>

			<!-- 추천검색 필터 -->
			<v-row class="ma-0 mb-4 mb-md-7 justify-center">
				<v-btn-toggle
					v-model="recommend_group"
					mandatory
					class="btn_recommend_group"
				>
					<v-btn
						checked
						class="mr-2 mr-md-3"
					>
						<svg
							width="31"
							height="30"
							viewBox="0 0 31 30"
						>
							<path
								d="M6.75 26.25C6.0625 26.25 5.47396 26.0052 4.98438 25.5156C4.49479 25.026 4.25 24.4375 4.25 23.75V6.25C4.25 5.5625 4.49479 4.97396 4.98438 4.48438C5.47396 3.99479 6.0625 3.75 6.75 3.75H24.25C24.9375 3.75 25.526 3.99479 26.0156 4.48438C26.5052 4.97396 26.75 5.5625 26.75 6.25V23.75C26.75 24.4375 26.5052 25.026 26.0156 25.5156C25.526 26.0052 24.9375 26.25 24.25 26.25H6.75ZM6.75 23.75H24.25V6.25H6.75V23.75ZM8 21.25H23L18.3125 15L14.5625 20L11.75 16.25L8 21.25ZM11.125 12.5C11.6458 12.5 12.0885 12.3177 12.4531 11.9531C12.8177 11.5885 13 11.1458 13 10.625C13 10.1042 12.8177 9.66146 12.4531 9.29688C12.0885 8.93229 11.6458 8.75 11.125 8.75C10.6042 8.75 10.1615 8.93229 9.79688 9.29688C9.43229 9.66146 9.25 10.1042 9.25 10.625C9.25 11.1458 9.43229 11.5885 9.79688 11.9531C10.1615 12.3177 10.6042 12.5 11.125 12.5Z"
							/>
						</svg>
						<span class="hidden-sm-and-down">사진<span class="hidden-md-and-down">으로</span> 추천</span>
					</v-btn>

					<v-btn class="ml-2 ml-md-3">
						<svg
							width="31"
							height="30"
							viewBox="0 0 31 30"
						>
							<path
								d="M15.5 26.25C12.7292 26.25 10.3698 25.2865 8.42188 23.3594C6.47396 21.4323 5.5 19.1042 5.5 16.375C5.5 15 5.76042 13.7292 6.28125 12.5625C6.80208 11.3958 7.52083 10.3542 8.4375 9.4375L15.5 2.5L22.5625 9.4375C23.4792 10.3542 24.1979 11.3958 24.7188 12.5625C25.2396 13.7292 25.5 15 25.5 16.375C25.5 19.1042 24.526 21.4323 22.5781 23.3594C20.6302 25.2865 18.2708 26.25 15.5 26.25ZM15.5 23.75V6L10.1875 11.25C9.45833 11.9375 8.91146 12.7135 8.54688 13.5781C8.18229 14.4427 8 15.375 8 16.375C8 18.3958 8.72917 20.1302 10.1875 21.5781C11.6458 23.026 13.4167 23.75 15.5 23.75Z"
							/>
						</svg>
						<span class="hidden-sm-and-down">색상<span class="hidden-md-and-down">으로</span> 추천</span>
					</v-btn>
				</v-btn-toggle>
			</v-row>

			<template v-if="recommend_group === 0">
				<!-- 사진으로 추천내용 -->
				<section
					v-if="!results_picture && !loading"
					class="py-5 px-4 pa-md-7 cont_recommend"
				>
					<HomeSearchPhotoReco :frommain="from_main" />
					<div class="intro">
						<v-img
							eager
							alt="마루추천 서비스 이용방법"
							:src="require(`@/assets/img/search/maru_recomm_content.svg`)"
						/>
						<v-img
							eager
							style="cursor: pointer"
							:src="require(`@/assets/img/search/maru_recomm_button.png`)"
							@click="common_scrollToTop"
						/>
					</div>
				</section>

				<!-- 사진분석결과 -->
				<section
					v-if="results_picture"
					class="py-5 px-4 pa-md-7 cont_recommend"
				>
					<HomeSearchPhotoAnalysis :items="results_picture" />
				</section>
				<br /><br />
			</template>

			<template v-else>
				<!-- 색상으로 추천내용 -->
				<section
					v-if="!results_color && !loading"
					class="py-5 px-4 pa-md-7 cont_recommend"
				>
					<HomeSearchColorReco />
					<div class="intro">
						<v-img
							eager
							alt="마루추천 서비스 이용방법"
							:src="require(`@/assets/img/search/maru_recomm_content.svg`)"
						/>
						<v-img
							eager
							style="cursor: pointer"
							:src="require(`@/assets/img/search/maru_recomm_button.png`)"
							@click="common_scrollToTop"
						/>
					</div>
				</section>

				<!-- 색상분석결과 -->
				<section
					v-if="results_color"
					class="py-5 px-4 pa-md-7 cont_recommend"
				>
					<HomeSearchColorAnalysis :items="results_color?.colorInfo" />
				</section>
				<br /><br />
			</template>

			<!-- 어울림지수 -->
			<section
				v-if="(recommend_group === 0 && results_picture) || (recommend_group === 1 && results_color)"
				class="px-4 px-sm-0"
			>
				<HomeSearchBefit :items="recommend_group === 0 ? results_picture?.result : results_color?.result" />
			</section>
		</div>
		<CommonLoading v-if="loading" />
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
export default {
	name: 'HomeSearchMaruRecommend',
	metaInfo: {
		title: '마루추천ㅣ동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '마루추천ㅣ동화자연마루',
			},
			{
				vmid: 'keywords',
				property: 'keywords',
				content: '인테리어, 마루추천, 사진촬영, 사진업로드, 색상검색, 마루찾기, 추천제품, 가구색상, 사진분석, ai분석',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '사진만 올리면 우리집에 어울리는 마루를 추천해드립니다. 마루추천 지금 이용해보세요!',
			},
			{
				vmid: 'url',
				property: 'og:url',
				content: `${process.env.VUE_APP_BASIC_SERVER}/search/maruRecommend`,
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: `https://www.greendongwha.com/img_og_maru_recommend.png`,
			},
		],
	},
	mixins: [common],
	data() {
		return {
			recommend_group: 0,
			results_picture: null,
			results_color: null,
			loading: false,
			from_main: false,
		}
	},
	computed: {
		...mapGetters(['HOME_GET_SEARCH_PICTURE_UPLOAD_FILE', 'HOME_GET_SEARCH_SELECTED_COLORS']),
	},
	watch: {
		async HOME_GET_SEARCH_PICTURE_UPLOAD_FILE(data) {
			if (data?.file) {
				this.callSearchItems({ picture: data?.file }, 'picture')
				this.recommend_group = 0
			} else this.results_picture = null
		},
		async HOME_GET_SEARCH_SELECTED_COLORS(data) {
			if (Object.keys(data).length > 0) {
				this.callSearchItems({ ...data }, 'color')
				this.recommend_group = 1
			} else this.results_color = null
		},
	},
	created() {
		const data = this.HOME_GET_SEARCH_PICTURE_UPLOAD_FILE
		if (data) {
			if (data?.file) {
				this.callSearchItems({ picture: data?.file }, 'picture')
				this.recommend_group = 0
			} else this.results_picture = null
		}

		const data2 = this.HOME_GET_SEARCH_SELECTED_COLORS
		if (data2.objectColor !== undefined || data2.wallColor !== undefined) {
			this.callSearchItems({ ...data2 }, 'color')
			this.recommend_group = 1
		} else this.results_color = null
	},
	mounted() {},
	beforeDestroy() {
		const file = this.HOME_GET_SEARCH_PICTURE_UPLOAD_FILE
		if (Object.keys(file).length > 0) this.HOME_MU_SEARCH_PICTURE_UPLOAD_FILE({})

		const color = this.HOME_GET_SEARCH_SELECTED_COLORS
		if (Object.keys(color).length > 0) this.HOME_MU_SEARCH_SELECTED_COLORS({})
	},
	methods: {
		...mapMutations(['APP_MU_DIALOG', 'HOME_MU_SEARCH_PICTURE_UPLOAD_FILE', 'HOME_MU_SEARCH_SELECTED_COLORS']),
		...mapActions(['HOME_ACT_SEARCH_RECOMMEND_LIST']),
		async callSearchItems(params, type) {
			this.loading = true
			await this.HOME_ACT_SEARCH_RECOMMEND_LIST(params).then(res => {
				if (res.result?.length > 0) {
					if (type === 'picture') this.results_picture = res
					else this.results_color = res
				} else {
					if (type === 'picture') {
						this.HOME_MU_SEARCH_PICTURE_UPLOAD_FILE({})
						this.results_picture = null
					}
					// this.APP_MU_DIALOG({
					// 	dialog: true,
					// 	text: '사진 업로드 실패하였습니다. 잠시 후 시도해주세요.',
					// })
				}

				this.loading = false
			})
		},
	},
}
</script>

<style scoped lang="scss">
.search_bg {
	background-color: $color_gray_1;
	height: 100%;
}
.v-application--is-ltr .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
	border-left-width: 1px !important;
}
.btn_recommend_group {
	&.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
		border-color: $color_gray_2 !important;
	}
	&.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn--active {
		border-color: $color_state_posi !important;
	}
	:deep(.v-btn) {
		border-radius: 99px !important;
		background-color: $color_white;
		padding: 0 32px !important;
		svg {
			fill: $color_gray_4 !important;
		}
		.v-btn__content {
			font-size: $font_xl;
			color: $color_gray_6;
		}
		&.v-btn--active {
			&::before {
				display: none !important;
			}
			svg {
				fill: $color_state_posi !important;
			}
			.v-btn__content {
				font-weight: $fw_bold !important;
				color: $color_state_posi !important;
			}
		}
	}
}
.cont_recommend {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
	position: sticky;
	top: 95px;
	z-index: 1;
	.intro {
		max-width: 678px;
		margin: 0 auto;
		padding: 30px 0 20px;
	}
}

@media all and (max-width: 959px) {
	.content_title {
		padding: 16px 0 !important;
	}
	.btn_recommend_group {
		height: 40px !important;
		&.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
			height: 40px !important;
		}
		:deep(.v-btn) {
			padding: 0 18px !important;
			.v-btn__content {
				svg {
					width: 24px !important;
					height: 24px !important;
				}
				font-size: $font_lg !important;
				.hidden-sm-and-down {
					display: block !important;
				}
			}
		}
	}
}
@media all and (max-width: 600px) {
	.cont_recommend {
		border-radius: 0 !important;
		box-shadow: none !important;
		top: 0px;
		position: relative;
	}
}
</style>
