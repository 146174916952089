<template>
	<div class="search_bg">
		<div class="pt-5 pt-md-10 wrapper">
			<v-container
				class="pa-0 px-0 px-sm-4"
				fluid
			>
				<!-- 검색결과가 있을때 -->
				<p class="mb-5 mb-md-10 tac no_result">
					<strong>{{ `“${get_keywords || ''}”` }}</strong>
					에 대한 검색 결과입니다.
				</p>

				<!-- 베스트상품 추천 -->
				<HomeSearchBestSearch
					v-if="searchResults.productInfo"
					:items="searchResults.productInfo || {}"
				/>

				<!-- 검색결과가 없을때 -->
				<HomeSearchSearchConfirm v-if="filterItems?.length === 0 && !loading" />

				<div v-if="filterItems?.length > 0">
					<!-- 탭 -->
					<CommonTabsTab04
						id="search-tab"
						v-model="tab"
						:tab-items="filterTabs"
						class-name="green mb-5"
					/>

					<!-- 전체카운트 및 정렬방식 -->
					<v-row
						v-if="!(searchResults.productInfo && tab === 0)"
						class="mx-0 mt-3 mb-1 my-md-5 px-3 px-sm-0 sort_bar"
						align="center"
					>
						<v-col
							cols="6"
							class="pa-0"
							align="start"
							align-self="center"
						>
							전체 <strong>{{ filterItems?.length || 0 }}</strong> 개
						</v-col>
						<v-col
							cols="6"
							class="pa-0 tawr"
							align="end"
							align-self="center"
						>
							<v-select
								v-model="selectModel"
								:items="searchItems"
								label="Solo field"
								dense
								prepend-inner-icon="list"
								solo
								class="sm_select"
							></v-select>
						</v-col>
					</v-row>
				</div>

				<!-- 카드노출: 통합검색/Masonry (지그재그) - 통합검색  -->
				<HomeSearchMasonry
					v-if="!searchResults.productInfo"
					:items="filterItems"
				/>

				<!-- 카드노출: 카테고리별 (제품별 집들이별 등등..) - 제품검색 -->
				<HomeSearchByCategory
					v-if="searchResults.productInfo"
					v-model="tab"
					class="default"
					:items="filterItems"
					:tabs="filterTabs"
				/>

				<!-- 카드노출 : 기본형태 (4개 → 3개 → 2개) - 검색결과 없을때 -->
				<CommonShowCardDefault
					v-if="searchResults.recommand?.length > 0"
					:items="searchResults.recommand?.slice(0, 8)"
				/>
			</v-container>
		</div>
		<CommonLoading v-if="loading" />
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import search from '@/mixins/search'

export default {
	name: 'HomeSearchResult',
	mixins: [common, search],
	metaInfo: {
		title: '통합검색ㅣ동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '통합검색ㅣ동화자연마루',
			},
			{
				vmid: 'keywords',
				property: 'keywords',
				content: '동화자연마루, 검색서비스, 통합검색, 인테리어, 스타일, 제품찾기, 제품명, 마루, 제품검색',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '동화자연마루의 검색서비스입니다. 제품명 또는 인테리어 스타일로 검색해보세요!',
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	data: () => ({
		tab: 0,
		tabItems: [
			{ title: '전체', index: 0, value: 'all' },
			{ title: '제품', index: 1, value: 'master' },
			{ title: '3D 집들이', index: 2, value: '3dhw' },
			{ title: '전문가집들이', index: 3, value: 'exphw' },
			{ title: '노하우', index: 4, value: 'knowhow' },
			{ title: '마루.잘.알.TV', index: 5, value: 'video' },
		],
		selectModel: '추천순',
		searchItems: ['추천순', '최신순', '인기순'],
		searchResults: [],
		loading: false,
	}),
	computed: {
		...mapGetters([
			'APP_GET_PAGE_LOAD',
			'APP_GET_SCROLL_BOTTOM',
			'APP_GET_APP_WIDTH',
			'APPBAR_GET_SEARCH_KEYWORDS',
			'HOME_GET_MAIN_SEARCH_TAGS',
			'HOME_GET_MAIN_BEST_PRODUCT',
		]),
		filterTabs() {
			// 아이템이 한 개 이상 있는 탭만 가져오기(없으면 비노출)
			let tabs = this.tabItems.filter(x => this.searchResults.result?.find(y => y.type === x.value))
			// 필터링한 탭 첫 번재에 '전체' 탭 추가하기
			tabs.unshift(this.tabItems[0])
			return tabs
		},
		filterItems() {
			if (this.filterTabs[this.tab].value === 'all') return this.searchResults.result || []
			else return this.searchResults.result?.filter(item => item.type === this.filterTabs[this.tab].value)
		},
	},
	watch: {
		get_keywords() {
			this.searchKeywordCheck()
		},
		tab() {
			const isPc = this.APP_GET_APP_WIDTH > 959
			let offset = 0

			if (isPc) {
				// 피씨 화면
				const scrollY = window.scrollY
				const tabPosY = document.querySelector('#search-tab').getBoundingClientRect().top + scrollY - 68 // 헤더높이 : 68px
				offset = scrollY > tabPosY ? 78 : 30
			}

			this.common_moveScroll('#search-tab', offset)
		},
		selectModel(val) {
			if (val === '추천순') this.searchResults.result?.sort((a, b) => (a.score > b.score ? -1 : 1))
			else if (val === '최신순') this.searchResults.result?.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			else this.searchResults.result?.sort((a, b) => (a.hits > b.hits ? -1 : 1))
		},
	},
	async created() {
		if (this.APPBAR_GET_SEARCH_KEYWORDS.length === 0) await this.getSearchList()
		if (this.HOME_GET_MAIN_SEARCH_TAGS?.length === 0) await this.getSearchProductList()
		if (this.HOME_GET_MAIN_BEST_PRODUCT.length === 0) await this.getBestProductList()

		this.searchKeywordCheck()
	},
	mounted() {},
	destroyed() {},
	methods: {
		...mapMutations(['APP_MU_DIALOG']),
		...mapActions([
			'HOME_ACT_SEARCH_KEYWORD_UNIVERSAL',
			'APPBAR_ACT_SEARCH_KEYWORDS',
			'HOME_ACT_MAIN_SEARCH_TAGS',
			'HOME_ACT_MAIN_BEST_PRODUCT',
		]),
		async getSearchList() {
			// 추천검색어
			await this.APPBAR_ACT_SEARCH_KEYWORDS().then(res => {
				console.log('APPBAR_GET_SEARCH_KEYWORDS', res)
			})
		},
		async getSearchProductList() {
			// 추천태그
			await this.HOME_ACT_MAIN_SEARCH_TAGS().then(res => {
				console.log('HOME_ACT_MAIN_SEARCH_TAGS', res)
			})
		},
		async getBestProductList() {
			// 베스트 제품
			await this.HOME_ACT_MAIN_BEST_PRODUCT().then(res => {
				console.log('HOME_ACT_MAIN_BEST_PRODUCT', res)
			})
		},
		async searchKeywordCheck() {
			if (this.get_keywords === undefined || this.get_keywords === null || this.get_keywords.trim() === '') {
				// this.APP_MU_DIALOG({
				// 	dialog: true,
				// 	title: '검색 오류',
				// 	text: '검색어를 입력해주세요.',
				// })

				// this.$router.replace('/')
				return
			}
			this.save_recent_keyword(this.get_keywords)
			this.loading = true
			const param = {
				keyword: this.get_keywords,
			}
			this.searchResults = await this.HOME_ACT_SEARCH_KEYWORD_UNIVERSAL(param) // 통합 키워드 검색
			console.log('searchResults', this.searchResults, this.searchResults.recommand)
			if (this.searchResults.patternInfo?.id) {
				window.open(`/product/detail/${this.searchResults.patternInfo?.id}`, '_blank')
				this.$router.go(-1)
			}
			this.tab = 0
			this.loading = false
		},
	},
}
</script>

<style scoped lang="scss">
.search_bg {
	background-color: $color_gray_1;
	height: 100%;
}

.no_result {
	font-size: $font_lg;
	color: $color_font;
}

.sort_bar {
	font-size: $font_normal;
	color: $color_font;
	.sm_select {
		border: 1px solid $color_gray_4;
		max-width: 80px !important;
		:deep(.v-text-field__details) {
			display: none !important;
		}
		:deep(.v-input__control) {
			min-height: 25px !important;
		}
		:deep(.v-input__append-inner) {
			display: none !important;
		}
		:deep(.v-icon) {
			font-size: 24px;
			color: $color_gray_11;
		}
		:deep(.v-input__slot) {
			padding: 0 5px !important;
			margin-bottom: 0 !important;
			box-shadow: none !important;
		}
		:deep(.v-select__selection) {
			text-align: center;
			font-size: 13px !important;
		}
		:deep(.v-select__selections)input {
			display: none !important;
		}
	}
}

@media all and (max-width: 600px) {
	.sort_bar {
		font-size: $font_sm !important;
		.sm_select {
			:deep(.v-select__selection) {
				font-size: $font_sm !important;
			}
			:deep(.v-icon) {
				font-size: 20px !important;
			}
		}
	}
}
</style>
